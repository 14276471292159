<template>
  <div id="pricing-page">
    <div class="panel rodin">
      <div class="content">
        <div class="labels-container">
          <span class="title">
            {{ $t(`pricing.title`) }}
          </span>
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="subtitle"
            v-html="$t(`pricing.subtitle`)"
          />
        </div>
        <div class="rodin-actions">
          <button
            class="btn-action demo hvr-float-shadow"
            @click="onContactUsClick"
          >
            {{ $t(`button['contact us']`) }}
          </button>
        </div>
      </div>
    </div>

    <div class="cards content panel">
      <div
        v-for="(item, index) in cards"
        :key="index"
        class="card"
      >
        <img
          :src="item.icon"
          :alt="item.title"
        >
        <span class="card__heading">{{ item.title }}</span>
        <span class="card__description">{{ item.description }}</span>
      </div>
    </div>
    <div class="services content panel">
      <contact-form
        class="contact-form"
        :header="$t('pricing.contactForm.header')"
        :subheader="$t('pricing.contactForm.subheader')"
        :show-contacts="false"
        source="PRICING"
        compact
      />
    </div>
  </div>
</template>
<script>
import ContactForm from '@/views/components/ContactForm/Index.vue';
import Customized from '@/assets/images/icons/customized.svg';
import Pay from '@/assets/images/icons/pay.svg';
import Coins from '@/assets/images/icons/coins.svg';
import Services from './service.json';

export default {
  name: 'PricingPage',
  components: { ContactForm },
  data() {
    return {
      cards: [
        {
          icon: Customized,
          title: this.$t('pricing[\'Customized to Your Needs\']'),
          description: this.$t('pricing[\'Different pricing stages\']'),
        },
        {
          icon: Pay,
          title: this.$t('pricing[\'Pay-as-You-Go\']'),
          description: this.$t('pricing[\'Pay-as-you-go\']'),
        },
        {
          icon: Coins,
          title: this.$t('pricing[\'Save More by Using More\']'),
          description: this.$t('pricing[\'The tiered pricing\']'),
        },
      ],
      services: Services.services,
      currentOption: 'languages.en',
      selectedService: Services.services[0].abbreviation,
      selectedSubservice: Services.subservices.NLP.services[0],
    };
  },
  computed: {
    subservices() {
      return Services.subservices[this.selectedService];
    },
    packages() {
      return Services.packages;
    },
    options() {
      return (this.selectedSubservice && this.selectedSubservice.options) || this.subservices.options || [];
    },
    selectedServiceItem() {
      return this.selectedService && this.services.find((item) => item.abbreviation === this.selectedService);
    },
  },
  methods: {
    onSelectService(item) {
      this.selectedService = item;
      this.selectedSubservice = Services.subservices[this.selectedService].services[0];
      this.currentOption = this.options.length > 0 ? this.options[0] : null;
    },
    onSelectSubService(item) {
      this.selectedSubservice = item;
      this.currentOption = this.options.length > 0 ? this.options[0] : null;
    },
    onContactUsClick() {
      this.$router.push({ name: 'contact-us' });
    },
  },
};
</script>
<style lang="scss" scoped>
  #pricing-page {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    background: #f7f8ff;
    color: $text-primary;

    .panel {
      display: flex;
      position: relative;
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 0.4rem 0.85rem 0;

      @include tablet-and-less {
        padding: 0 0.2rem;
      }
    }

    .coming-soon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      span {
        font-weight: 600;
        font-size: 0.18rem;
        line-height: 0.2rem;
        margin-top: 0.1rem;
      }
    }

    .rodin {
      background: url('~@/assets/images/background/pricing-bg-2.svg') -3% bottom no-repeat,
      url('~@/assets/images/background/pricing-bg-1.svg') left top no-repeat,
      url('~@/assets/images/background/pricing-bg-3.svg') right top no-repeat,
      $background-header;
      color: white;

      .content {
        padding: 1.81rem 0.85rem 0.4rem;

        .labels-container {
          z-index: 1;
          display: flex;
          flex-direction: column;

          .title {
            font-weight: 700;
            font-size: 0.36rem;
          }

          .subtitle {
            margin-top: 0.12rem;
            font-size: 0.18rem;
            font-weight: 400;
          }
        }

        .rodin-actions {
          z-index: 1;
          display: flex;
          margin-top: 0.43rem;

          .demo {
            padding: 0.19rem 0.53rem;
            margin-right: 0.2rem;
            background: $background-primary;
          }
        }
      }
    }

    .vision {
      display: flex;
      margin-bottom: -0.7rem;
    }

    .el-row {
      margin: 0 -0.2rem;
    }

    .cards {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .card {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        min-height: 2rem;
        border-bottom: 2px solid $text-dimmed;

        &__heading {
          margin-top: 0.2rem;
          font-size: 0.2rem;
          font-weight: 800;
          color: $text-primary;
        }

        &__description {
          margin-top: 0.2rem;
          margin-bottom: 0.25rem;
          font-size: 0.14rem;
          line-height: 1.8;
          color: $text-tertiary;
        }
      }
    }
    .contact-form {
      padding: 0.6rem 0;
    }

    @include tablet-and-less {
      .services__item {
        width: unset;
      }

      .service__heading {
        display: none;
      }
    }
  }
</style>
